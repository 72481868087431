/* You can add global styles to this file, and also import other style files */

@import './assets/reset';


@font-face {
    font-family: "Kaftan";
    src: url("./assets/fonts/Kaftan\ Serif.woff2") format("woff2"),
        url("./assets/fonts/Kaftan\ -\ Trial.otf") format("otf"),
        url("./assets/fonts/Kaftan\ Serif.woff") format("woff");
}

@font-face {
    font-family: "NeueMontreal";
    src: url("./assets/fonts/PPNeueMontreal-Regular.woff2") format("woff2"),
        url("./assets/fonts/PPNeueMontreal-Regular.woff") format("woff"),
        url("./assets/fonts/PPNeueMontreal-Regular.otf") format("otf"),
        url("./assets/fonts/PPNeueMontreal-Regular.ttf") format("ttf");
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;

    font-family: 'NeueMontreal', sans-serif;

    canvas {
        position: fixed;
        top: 0px;
        z-index: -1;
    }
}

* {
    box-sizing: border-box;
}

.reveal {
    overflow: hidden;

    &:not(.splited) {
        visibility: hidden;
    }

    .oh {
        // overflow: hidden;
        .line {
            transition: transform .6s linear;
            transform: translate(0px, 180%) rotate(5deg);
        }
    }

    &.visible {
        .oh {
            .line {
                transform: translate(0px, 0%) rotate(0deg);
            }
        }
    }
}


h1.conference-date.reaveal .oh {
    overflow: hidden;
}

h3.location .oh {
    overflow: hidden;
}

@media only screen and (max-width: 1199px) {
    html {
        canvas {
            position: absolute;
            right: 0px;
        }
    }
}

i {
    font-style: italic;
}

em {
    font-style: italic;
}

strong, b {
    font-weight: bold;
}